import React from "react"
import "../scss/index.scss"
import { AnchorLink } from "gatsby-plugin-anchor-links";
import Layout from "../components/layout/layout"
import Header from "../components/layout/header"
import Double from "../components/layout/double"
import Footer from "../components/layout/footer"
import CaseStudy from "../../static/img/case-study.jpg"
import { Helmet } from "react-helmet"
import HeadBlock from "../components/head-block/head-block";

export default function Home() {
  return <>
  <Helmet>
          <meta charSet="utf-8" />
          <title>Eco Wash Solutions | Anywhere Anytime We Come To You</title>
          <link rel="canonical" href="https://ecocarwash.co.nz" />
        </Helmet>
  <Header />
  <Layout>
  {/* Header Block */}
  <HeadBlock banner={CaseStudy} pretitle="Modern Edquipment" title="Case Studies" description="Anywhere Anytime We Come to You" />
   {/* Intro Block */}
   <Double align="left" type="light">
   <div className="double-block" id="about">
        <div className="block-item block-item--center">
          <a className="block-item__imageContainer" href="/case-studies/fulton-hogan"><img className="icon-xlarge" src={CaseStudy}  alt="Car Wash Solutions - Eco Wash Solutions" loading="lazy" /></a>
          <h4 className="mb--01">Fulton Hogan</h4>
          <AnchorLink className="button button--large button--primary" to="/case-studies/fulton-hogan">Read more..</AnchorLink>
        </div>
      </div>
      <div className="double-block">
      <div className="block-item block-item--center">
        <a className="block-item__imageContainer" href="/case-studies/cake-commercial"><img className="icon-xxlarge" src={CaseStudy}  alt="Car Wash Solutions - Eco Wash Solutions" loading="lazy" /></a>
          <h4 className="mb--01">Cake Commercial</h4>
          <AnchorLink className="button button--large button--primary" to="/case-studies/cake-commercial">Read more..</AnchorLink>
        </div>
      </div>
   </Double>   

  </Layout>
  <Footer copyright="Copyright &copy; 2020 - 2023 Eco Wash Solutions. All Rights Reserved" />
  </>
}
